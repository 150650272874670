var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container-lg px-4 content py-5"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-outline-primary mb-3",attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 返回列表 ")])]),_c('div',{staticClass:"col-12 title fs-1 fw-bold"},[_vm._v("机器学习研究员")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jumbotron-wrapper image"},[_c('div',{staticClass:"jumbotron container-lg px-4"},[_c('div',{staticClass:"animation cover-text mt-5 pt-5 ps-0"},[_c('div',[_vm._v("来非凸，拥抱每一种可能！")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section mt-3"},[_c('div',{staticClass:"badge border text-primary border-primary"},[_vm._v("机器学习")]),_c('div',{staticClass:"ms-2 badge border text-primary border-primary"},[_vm._v("策略")]),_c('div',{staticClass:"ms-2 badge border text-primary border-primary"},[_vm._v(" 校园招聘 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("招聘对象")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 2025届海内外高校毕业生 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位描述")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 参与设计开拓性的智能算法应用于交易； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 参与构建科学、严谨的算法评测体系； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 紧跟领域前沿，推动基础研究； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 利用机器学习、深度学习和人工智能等方法，对历史数据进行研究、分析和统计，并从中寻找相关的趋势和规律。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位要求")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 2025届本科及以上学历，金融、物理、数学、计算机等理科背景； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 专业基础知识扎实，熟悉机器学习（深度学习），具备创新研究能力； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 至少熟悉一种编程语言：Rust/C++/Python/Go/Java； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 对人工智能及数智交易有浓厚的兴趣，思考深入，自我驱动，能快速学习新鲜事物。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("工作城市")]),_c('div',{staticClass:"mt-2"},[_c('span',[_vm._v(" 上海 ")]),_c('span',[_vm._v(" 北京 ")]),_c('span',[_vm._v(" 深圳 ")]),_c('span',[_vm._v(" 成都 ")]),_c('span',[_vm._v(" 徐州 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("投递简历")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 标题格式：2025校招-姓名-毕业院校-专业-岗位名称 ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 邮件内容：可以充分自由发挥，最后附上个人简历 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("联系方式")]),_c('div',{staticClass:"mb-2"},[_vm._v("简历请发送至邮箱：recruit@ft.tech")]),_c('img',{attrs:{"src":require("../../assets/img/招聘求职.jpg"),"align":"center"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-primary px-5",attrs:{"href":"mailto:recruit@ft.tech"}},[_vm._v(" 简历投递 ")])])
}]

export { render, staticRenderFns }